import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <Subtitle>Features</Subtitle>
      <SectionTitle>Permit Application</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Progress</FeatureTitle>
          <FeatureText>Track and monitor the application process.</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Notifications</FeatureTitle>
          <FeatureText>
            Receive notifications when additional action is required to
            continue application process.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Specification Checking</FeatureTitle>
          <FeatureText>
            Get feedback when application does not meet railroad requirements
            before submitting.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Documents</FeatureTitle>
          <FeatureText>
            Upload supporting documents for application.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

export const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

export const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

export const FeatureItem = styled.div`
  display: flex;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

export const FeatureText = styled.p`
  text-align: center;
`
