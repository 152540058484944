import React, {Component } from "react"
import styled from "styled-components"
import { Container, Section } from "../global"
import "../../styles/hubspot.css"

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const ContactContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const Title = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

const Subtitle = styled.span`
  ${props => props.theme.font_size.xxsmall}
  padding-top: 16px;
  font-size: 14px;
  color: ${props => props.theme.color.primary};
`
export default class Contact extends Component {

componentDidMount() {
  const script = document.createElement('script');
  script.src = 'https://desk.zoho.com/portal/api/feedbackwidget/493433000000654001?orgId=712962277&displayType=embeded';
  document.body.appendChild(script);
  
  // script.addEventListener('load', () => {
  //   if(window.hbspt) {
  //     window.hbspt.forms.create({
  //       portalId: '2830143',
  //       formId: 'e034ae46-9b57-459f-b6d1-308c2749b7f9',
  //       target: '#hubspotForm'
  //     })
  //   }
  // });
}

render() {
  return (
  <Section id="contact">
  <StyledSection>
    <ContactContainer>
      <Title>Contact Us</Title>
   		  <div id="zsfeedbackwidgetdiv"></div>
    </ContactContainer>
  </StyledSection>
  </Section>
)
  }
}